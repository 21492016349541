<template>
  <v-dialog v-model="localDialog" max-width="80%">
    <v-card>
      <v-toolbar dense transparent elevation="0">
        <v-toolbar-title>Activity log</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="updateDialog(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-timeline dense align-top v-if="!loading && activityLog.length > 0">
        <v-slide-x-reverse-transition group>
          <v-timeline-item
            small
            v-for="item in activityLog"
            :key="item.id"
            class="pa-3"
          >
            <v-row class="pt-1" no-gutters>
              <v-col cols="3">
                <strong>{{ formatDate(item.created_at) }}</strong>
              </v-col>
              <v-col>
                <strong>{{ item.log_name }}</strong> - {{ item.description }}
                <div class="caption" v-if="item.causer">
                  by {{ item.causer.name }} ({{ item.causer.email }})
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
      <v-alert
        v-else-if="loading"
        border="bottom"
        class="mb-0"
        icon="mdi-timeline-clock"
        transition="scale-transition"
      >
        <v-progress-circular indeterminate :color="color" />
      </v-alert>
      <v-alert
        v-if="!loading && activityLog.length == 0"
        :color="color"
        dark
        border="bottom"
        class="mb-0"
        icon="mdi-timeline-clock"
        transition="scale-transition"
      >
        No logs for this activity
      </v-alert>
    </v-card>
  </v-dialog>
</template>
<script>
import date from "@/mixins/date";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    namespace: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    id: {
      type: [String, Number],
      required: true
    }
  },

  mixins: [date],
  components: {},
  computed: {
    activityLog: {
      get() {
        return this.$store.getters[this.namespace + "/activityLog"];
      }
    },
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog-update", value);
      }
    }
  },
  async created() {
    await this.$store.dispatch(this.namespace + "/getActivityLog", this.id);
  },
  methods: {
    updateDialog(dialog) {
      this.localDialog = dialog;
    },
    cancelDialog() {
      this.$emit("dialog-cancel", true);
    }
  }
};
</script>
